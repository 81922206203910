import * as React from 'react'
import { FC } from 'react'
import { Main } from 'layouts'
import { SiteSettings } from 'config/types'
import { AdtPixel } from 'components/_pixels'
import { RootStore } from 'stores'
import { PersonalSleepConnected } from 'components/Phantom/_sections/PersonalSleep'
import { SlideOutBenefits } from 'components/Phantom/_sections/SlideOutBenefits'
import { FeaturedReviews } from 'components/Phantom/_sections/FeaturedReviews'
import { AutopilotBrieferStoreConnected } from 'components/Phantom/_sections/AutopilotBriefer'
import { Benefits } from 'components/Phantom/_sections/ScrollSequenceStatic'
import { VanityHighlight } from 'components/Phantom/VanityHighlight'
import { Img, ImgBackground } from 'components/basic/Img'
import styles from './Vanity.module.scss'
import { ConnectedShop } from 'components/Phantom/_pageCompositions/Shop'
import { NewStandardConnected } from 'components/Phantom/_sections/NewStandard'

interface Props {
	settings: SiteSettings
	renderFinancing: boolean
	maxMonthsAtZeroInterest: number
	monthlyPriceFormatted: string
	rootStore: RootStore
	cartLoading?: boolean
}

const CustomHero = (key: string) => {
	if (key === 'healf') {
		return (
			<section className={styles.hero_container}>
				<ImgBackground
					src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/healf/healf-hero-desktop.jpg'}
					alt={''}
					sources={[{ mediaQuery: '(max-width: 1023px)', src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/healf/healf-hero-mobile.jpg' }]}
					lazy={false}
					dprHeight={1000}
					className={styles.hero_image}
				>
					<div className={styles.shade}></div>
					<div className={styles.hero_content}>
						<div>
							<Img
								alt={'Eight Sleep Logo'}
								src={'https://eightsleep.imgix.net/Logo_White.svg?10878386720812330155'}
								className={styles.logo}
							/>
							<Img
								alt={'Strava lock badge'}
								src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/strava/strava-lock-badge.svg'}
								className={styles.lock_badge}
							/>
							<Img
								src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/healf/healf-logo.svg'}
								alt={'Strava logo'}
								className={styles.strava_badge}
							/>
						</div>
					</div>
				</ImgBackground>
			</section>
		)
	}
	if (key === 'lifetime') {
		return (
			<section className={styles.hero_container}>
				<ImgBackground
					src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lifetime-heroimg.jpeg'}
					alt={''}
					sources={[{ mediaQuery: '(max-width: 1023px)', src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lifetimeheromob.jpg' }]}
					lazy={false}
					dprHeight={1000}
					className={styles.hero_image}
				>
					<div className={styles.shade}></div>
					<div className={styles.hero_content}>
						<div>
							<Img
								alt={'Eight Sleep Logo'}
								src={'https://eightsleep.imgix.net/Logo_White.svg?10878386720812330155'}
								className={styles.logo}
							/>
							<Img
								alt={'Strava lock badge'}
								src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/strava/strava-lock-badge.svg'}
								className={styles.lock_badge}
							/>
							<Img
								src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lifetime-white-logo.svg'}
								alt={'Strava logo'}
								className={styles.strava_badge}
							/>
						</div>
					</div>
				</ImgBackground>
			</section>
		)
	}
	return null
}

export const Vanity: FC<Props> = (props) => {
	const { settings, rootStore } = props
	const { priceStore } = rootStore
	const discountFormatted = priceStore.pod4UltraDiscountFormatted

	return (
		<Main>
			<section
				className="bg-white"
				style={{ marginTop: '40px', display: 'flex' }}
			>
				{CustomHero(settings.page) ?? (
					<VanityHighlight
						title={settings.heroSettings?.heading || `${settings.partnerName} approves the Pod`}
						author={settings.heroSettings?.description}
						quote={settings.homeSettings.heroTitle}
						boldQuote
						phantomImage={settings.phantomImage}
						bgImg={settings.heroSettings?.bgImage}
						sources={settings.heroSettings?.sources}
						alt={settings.emailCapSettings?.imageAlt || ''}
						ctaLabel={`Unlock ${discountFormatted} off`}
						className="outer-margins-bottom"
					/>
				)}
			</section>
			<NewStandardConnected />
			<ConnectedShop />
			<PersonalSleepConnected />
			<SlideOutBenefits />
			<FeaturedReviews />
			<AutopilotBrieferStoreConnected />
			<section>
				<VanityHighlight
					title={settings.preFooterSettings?.title ?? `${settings.partnerName} approved`}
					quote={settings.preFooterSettings?.paragraphs?.[0] ?? `${settings.partnerName} swears by the Pod Cover to get better sleep. Learn more for yourself.`}
					phantomImage={settings.phantomImage}
					bgImg={settings.heroSettings?.bgImage}
					sources={settings.preFooterSettings?.sources ?? settings.heroSettings?.sources}
					alt={settings.emailCapSettings?.imageAlt || ''}
					ctaLabel={`Shop now`}
					subtitle="The Pod"
					subheadline={`Unlock ${discountFormatted} off`}
					className="inner-paddings-minor-bottom"
				/>
			</section>
			<section>
				<Benefits />
			</section>
			<AdtPixel id="6563" />
		</Main>
	)
}
