import { ClientOnly } from 'components/ClientOnly'
import React, { FC } from 'react'

interface AdtPixel {
	id: string
}

const AdtPixelBase: FC<AdtPixel> = (props) => {
	// const ref = useRef(null);
	// if (ref.current) {
	// 	return null;
	// }
	return (
		<ClientOnly>
			<img
				src={`https://px.adentifi.com/Pixels?a_id=${props.id};uq=${Date.now()};`}
				height="0"
				width="0"
				style={{ display: 'none' }}
				alt=""
				// ref={ref}
			/>
		</ClientOnly>
	)
}

export const AdtPixel = React.memo(AdtPixelBase)
