import { GetStaticPaths, GetStaticProps } from 'next'
import { generateVanityUrls, vanityConfig } from 'config/vanity'
import { Vanity } from 'components/Vanity/Vanity'
import { inject, observer } from 'mobx-react'
import { RootStore } from 'stores/index'
import { VanityData } from 'config/types'
import { config } from 'utils/internationalization'
import { generatePathPermutationsForExperiments } from 'ab_testing'
import fs from 'fs'

interface IStaticProps {
	rootStore: RootStore
	vanity_data: VanityData | null
}

function Index({ rootStore }: IStaticProps) {
	const { isUS } = rootStore.settingsStore
	const cartLoading = rootStore.cartStoreNew.updatingCart // DO NOT delete, this forces a rerender in Shopmain for some reason

	return (
		<Vanity
			settings={rootStore.settingsStore.settings}
			renderFinancing={isUS}
			maxMonthsAtZeroInterest={rootStore.priceStore.coverMaxMonthsAtZeroInterest}
			monthlyPriceFormatted={rootStore.priceStore.coverMonthlyPriceFormatted}
			rootStore={rootStore}
			cartLoading={cartLoading}
		/>
	)
}

export const getStaticPaths: GetStaticPaths = async () => {
	const vanity_paths = generateVanityUrls().flatMap((p) => {
		return config.allRegions.map((it) => ({ params: { ...p.params, locale: it } }))
	})

	const paths = vanity_paths.flatMap((it) => generatePathPermutationsForExperiments(it.params.t1).map((p) => ({ params: { ...it.params, experiments: p } })))

	try {
		const paths = vanity_paths.map((it) => `${it.params.locale !== 'us' ? '/' + it.params.locale : ''}/${it.params.t1}`)
		fs.writeFileSync('vanities.json', JSON.stringify(paths))
	} catch (error) {
		console.log('Error updating vanities JSON', error)
	}

	if (process.env.NEXT_PUBLIC_IS_PROD) {
		return {
			paths,
			fallback: false,
		}
	}

	if (process.env.NEXT_PUBLIC_IS_STAGING) {
		const vitalT1s = ['huberman', 'pomp', 'mkbhd', 'rogan']

		return {
			paths: paths.filter((a) => vitalT1s.includes(a.params.t1)),
			fallback: 'blocking',
		}
	}

	// Don't prerender all T1s by default, only on production
	return {
		paths: [],
		fallback: 'blocking',
	}
}

export const getStaticProps: GetStaticProps = async ({ params }) => {
	const t1 = params?.t1 as string
	const pathname = t1.trim()
	const props: Partial<IStaticProps> = {
		vanity_data: {
			...vanityConfig[pathname],
			page: pathname,
		},
	}

	return {
		props,
	}
}

export default inject('rootStore')(observer(Index))
