import cx from 'classnames'
import styles from './VanityHighlight.module.scss'
import { Img, Source, srcToImgix } from 'components/basic/Img'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { amClickedButton } from 'events/amplitude'
import { Button } from 'components/WebEv/Buttons'
import { FC } from 'react'
import useIsMobile from 'hooks/useIsMobile'

export interface VanityHighlightProps {
	title: string
	quote: string
	phantomImage?: string
	bgImg?: string
	sources?: Source[]
	alt: string
	ctaLabel: string
	subheadline?: string
	subtitle?: string
	boldQuote?: boolean
	author?: string[]
	className?: string
}

const defaultVanityHeroImage = {
	desktop: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2024-phantom-launch/blog-home-desktop.jpg',
	mobile: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/2024-phantom-launch/blog-home-mobile.jpg',
}

export const VanityHighlight: FC<VanityHighlightProps> = (props) => {
	const isMobile = useIsMobile()
	const mobileImage = props.sources?.[0]?.src ?? defaultVanityHeroImage.mobile
	const image = props.phantomImage ?? props.sources?.[isMobile ? 0 : props.sources.length - 1]?.src ?? defaultVanityHeroImage.desktop

	return (
		<div className={`inner-paddings-x inner-paddings-top ${props.className} ${styles.hero_full_background}`}>
			<div
				className={styles.gradient_background}
				style={{
					background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.5) 100%), url('${srcToImgix(props.bgImg || image, false)}&h=500&dpr=1'), lightgray 50%`,
					backgroundPosition: 'center',
					backgroundSize: isMobile ? 'contain' : 'cover',
					backgroundRepeat: isMobile ? 'repeat' : 'no-repeat',
				}}
			></div>
			<div className={styles.new_hero}>
				<div className={styles.hero_img}>
					<Img
						dprHeight={1000}
						simple
						lazy={false}
						src={isMobile ? mobileImage : image}
						alt={props.alt}
						className={props.phantomImage ? styles.main_image : styles.circle_image}
					/>
				</div>
				<div className={cx(styles.cnt)}>
					<h1 className={styles.partner_name}>{props.title}</h1>
					<p
						className={props.boldQuote ? styles.partner_quote : styles.partner_quote2}
						dangerouslySetInnerHTML={{ __html: props.quote }}
					></p>
					{props.author && props.author.filter((it) => !!it).length > 0 && (
						<p className={styles.author}>
							{props.author.map((it, i) => (
								<span key={`author-${i}`}>
									{it}
								</span>
							))}
						</p>
					)}
					{props.subheadline && <p className={styles.subheadline}>{props.subheadline}</p>}
					{props.subtitle && <p className={styles.subtitle}>{props.subtitle}</p>}
					<AnchorLink
						offset={50}
						href={'#shop'}
						className={styles.cta}
					>
						<Button.Secondary
							id={'vanity-hero-cta'}
							onClick={() => {
								amClickedButton('Vanity learn more', 'vanity-hero-cta')
							}}
						>
							{props.ctaLabel}
						</Button.Secondary>
					</AnchorLink>
				</div>
			</div>
		</div>
	)
}
